import { get, map } from 'lodash'

/**
  return data:
  [
    {category: {...}, product: {...}, unit: {...}},
    {category: {...}, product: {...}, unit: {...}},
  ]
*/
export function getAllProductsList (orderStack) {
  const products = []
  for (const unit in orderStack) {
    products.push(...orderStack[unit])
  }
  return products
}

/**
  return data:
  [
    {category: {...}, product: {...}, unit: {...}},
    {category: {...}, product: {...}, unit: {...}},
  ]
*/
export function needRebateProducts (config, orderStack) {
  const IgnoreProducts = config.IgnoreAddSalesProducts || []
  if (!IgnoreProducts) return
  const IgnoreIds = map(IgnoreProducts, 'id')
  const products = getAllProductsList(orderStack)
  if (!products) return []
  return products.filter(p => !IgnoreIds.includes(p.product.id))
}

export function sumAllProductPrice (products) {
  let total = 0
  products.forEach((item) => {
    const count = item.product.count || 1
    const price = item.product.defaultPrice || 0
    total += Number(count * price)
  })
  return total
}

// 減去打折實體金額
export const calcCashDiscount = (cashProducts, totalItemPrice) => {
  const data = {
    discountLabelStack: [], // 顯示在畫面上的打折註解
    discountPrice: 0,
    totalItemPrice,
  }

  cashProducts.forEach((item) => {
    if (data.totalItemPrice - item.cashAmount > 0) {
      data.discountLabelStack.push(`${item.name}-${item.cashAmount}`)
      data.totalItemPrice -= item.cashAmount
      data.discountPrice += item.cashAmount
    } else if (data.totalItemPrice - item.cashAmount <= 0) {
      data.discountPrice += data.totalItemPrice
      data.discountLabelStack.push(`${item.name}-${data.totalItemPrice}`)
      data.totalItemPrice = 0
    }
  })

  return data
}

// 減去打折比例金額
export const calcRatioDiscount = (ratioProducts, totalItemPrice) => {
  const data = {
    discountLabelStack: [], // 顯示在畫面上的打折註解
    discountPrice: 0,
    totalItemPrice,
  }
  ratioProducts.forEach((item) => {
    const price = Math.round(data.totalItemPrice - Math.round(data.totalItemPrice * item.percentAmount))
    data.discountLabelStack.push(`${item.name}-${price}`)
    if (price > 0) {
      data.totalItemPrice -= price
      data.discountPrice += price
    } else if (price <= 0) data.totalItemPrice = 0
  })
  return data
}

// 回饋金計算
export const calcRebateDiscount = ({ totalPrice, orderStack, paymentStack, config }) => {
  const configRatio = config.addRate || 0

  const allProductList = getAllProductsList(orderStack)
  const needRebateList = needRebateProducts(config, orderStack)
  console.log('needRebateList', needRebateList)
  const needRebatePrice = sumAllProductPrice(needRebateList) || 0
  const allProductPrice = sumAllProductPrice(allProductList) || 0
  let productRatio = needRebatePrice / allProductPrice
  if (Number.isNaN(productRatio) || productRatio === Infinity) productRatio = 0
  // 加回付款金額
  let totalPaymentPrice = 0
  paymentStack.forEach(payment => {
    if (payment.type !== 'wallet') totalPaymentPrice += payment.amount
  })
  totalPrice += totalPaymentPrice

  return Math.floor(totalPrice * productRatio * configRatio)
}

// 計算總金額 (未折任何折扣)
export const unDiscountTotalItemPrice = (orderStack) => {
  /*
    orderStack: {
      unitA: [
        {category: {...}, product: {...}, unit: {...}},
        {category: {...}, product: {...}, unit: {...}},
      ]
    }
  */

  let total = 0

  // 將所有人員底下的 product 解出到 items 陣列裡
  const items = getAllProductsList(orderStack)
  // 計算所有 product 的總價並加總
  total = sumAllProductPrice(items)
  // items.forEach((item) => {
  //   const count = item.product.count || 1
  //   const price = item.product.defaultPrice || 0
  //   total += Number(count * price)
  // })

  return total
}
