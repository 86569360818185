<!-- eslint-disable -->
<template>
  <div class="sales-record-create" :class="{ collapse: sidebar && !isMobile }" v-loading="loading.page">
    <!-- // 左邊欄 // -->
    <div class="left-panel">
      <!-- 頭部控件 -->
      <section class="left-header">
        <div
          class="member-card"
          :class="{ 'block': member.isBlock }"
          @click="goMemberProfile(member)"
        >
          <!-- TODO member icon -->
          <i class="el-icon-user-solid mr-2" style="font-size: 24px;" />
          <div
            class="flex flex-col"
            v-if="
              meta.type === 'create' ||
                (meta.type === 'payment' && !salesConfig.identityMode)
            "
          >
            <span>{{ member.UserInfo.name }}</span>
            <span>{{ member.UserInfo.phone }}</span>
          </div>

          <div
            class="flex flex-col"
            v-if="
              meta.type === 'identity' ||
                (meta.type === 'payment' && salesConfig.identityMode)
            "
          >
            <span class="identity">{{ identity.name }}</span>
          </div>
        </div>
        <!-- 重輸入電話按鈕 -->
        <el-button
          type="primary"
          class="phone-search-btn"
          @click="onCancelPhone"
          v-if="!salesConfig.identityMode"
        >
          <!-- TODO member icon -->
          <i class="el-icon-menu mr-1" />
          <span>重輸號碼</span>
        </el-button>

        <div
          class="phone-search-btn"
          @click="selectIdentity = true"
          v-if="salesConfig.identityMode"
        >
          <span>重選身分</span>
        </div>
      </section>

      <!-- 購物車清單列表 -->
      <section class="orders-list">
        <EmptyBlock v-if="isEmpty(orderStack)" />

        <div
          class="unit-row"
          v-for="item in orderStack"
          :key="item[0].unit.id"
        >
          <span class="unit-name mb-2">{{ item[0].unit.name }}</span>

          <div
            class="unit-order-item flex flex-between mb-3"
            v-for="(order, index) in item"
            :key="`${item[0].unit.name} - ${index}`"
          >
            <i
              class="el-icon-circle-close close-icon"
              @click="removeOrder(item[0].unit.name, index)"
            />
            <div class="item-block">
              <span class="order-item-count mr-2">{{
                order.product.count
              }}</span>
              <span class="order-item-name">{{ order.product.name }}</span>
            </div>
            <span class="unit-order-items-list"
              >$ {{ order.product.defaultPrice || 0 }}</span
            >
          </div>
        </div>
      </section>

      <!-- 金額統計、控件 -->
      <section class="left-footer">
        <div class="btn-container">
          <el-button
            v-if="meta.type === 'create' || meta.type === 'identity'"
            class="w-100"
            plain
            @click.async="resetOrderStack(); toggleActiveStep('category');"
            >重置明細</el-button
          >
          <el-button
            v-if="meta.type === 'payment'"
            class="w-100"
            plain
            @click="backToCreate"
            >更改明細</el-button
          >
          <el-button
            v-if="meta.type === 'create' || meta.type === 'identity'"
            class="w-100"
            type="primary"
            :disabled="isEmpty(orderStack)"
            @click="goPayment"
            >下一步</el-button
          >
          <el-button
            v-if="meta.type === 'payment'"
            class="w-100"
            type="primary"
            :disabled="isEmpty(orderStack)"
            @click="handlePayConfirm"
            >出帳明細</el-button
          >
        </div>
      </section>
    </div>

    <!-- // 右邊視圖欄 - 身分別 // -->
    <div class="right-panel" v-if="selectIdentity && salesConfig.identityMode">
      <section class="list-wrapper">
        <div class="items-list">
          <div
            class="item-card"
            v-for="role in salesConfig.identities"
            :key="role.code"
            @click=";(identity = role), (selectIdentity = false)"
          >
            <span class="name">{{ role.name }}</span>
          </div>
        </div>

        <div
          v-if="showPagination(dataSet.category)"
          style="padding-left: 5px; padding-right: 5px"
          class="pagination-container"
        >
          <i
            class="el-icon-arrow-left page-btn"
            @click="pageChange('sub', dataSet.category)"
          />
          <i
            class="el-icon-arrow-right page-btn"
            @click="pageChange('add', dataSet.category)"
          />
        </div>
      </section>
    </div>

    <!-- // 右邊視圖欄 // -->
    <div
      class="right-panel"
      v-if="
        meta.type === 'create' || (meta.type === 'identity' && !selectIdentity)
      "
      v-loading="loading.rightPanel"
    >
      <section class="right-header flex justify-content-center">
        <div class="step-header">
          <span
            class="step-item"
            :class="{
              active:
                (isEmpty(order.product) && isEmpty(order.unit)) ||
                !isEmpty(order.category),
              done: !isEmpty(order.category),
            }"
            @click="toggleActiveStep('category'); resetOrderData(['category', 'product', 'unit']);"
          >
            <span>1.類別</span>
          </span>
          <span
            class="step-item"
            :class="{
              active:
                (!isEmpty(order.category) && isEmpty(order.unit)) ||
                !isEmpty(order.product),
              done: !isEmpty(order.product),
              'no-unit': !isEmpty(order.product) && !order.product.needUnit,
            }"
            @click="toggleActiveStep('product'); resetOrderData(['product', 'unit']);"
          >
            <span>2.產品</span>
          </span>
          <span
            class="step-item"
            :class="{
              active:
                (!isEmpty(order.category) &&
                  !isEmpty(order.product) &&
                  isEmpty(order.unit)) ||
                !isEmpty(order.unit),
              hide: !isEmpty(order.product) && !order.product.needUnit,
              done: !isEmpty(order.unit),
            }"
            @click="toggleActiveStep('unit'); resetOrderData(['unit']);"
          >
            <span>3.人員</span>
          </span>
        </div>
      </section>

      <!-- 群組 -->
      <div v-show="hiddenGroupNav" class="w-full h-[56px]"></div>
      <section
        v-show="!hiddenGroupNav"
        class="group-wrapper"
      >
        <div class="tab-wrapper">
          <a
            v-for="item in availableGroupList"
            :key="item.id"
            class="tab__item"
            :class="{ active: activeGroup === item.id }"
            :href="`#${item.name}`"
            @click="toggleActiveGroup(item.id)"
          >
            {{ item.name }}
          </a>
        </div>
      </section>

      <!-- 類別 -->
      <section v-if="showCategory" class="list-wrapper">
        <EmptyBlock v-if="dataSet.category.length === 0" />

        <div
          class="category__block"
          v-for="item in dataSet.category"
          :key="item.id"
        >
          <div
            v-show="showCategoryLabel(item.list) && !onlyOthersCategory"
            :id="item.name"
            class="category__label"
          >
            {{ item.name }}
          </div>

          <div class="items-list">
            <div class="item-card"
              v-for="category in item.list"
              :key="category.id"
              @click="toggleActiveStep('product'), selectCategory(category), (page = 0)"
            >
              <span class="name">{{ category.name }}</span>
            </div>
          </div>
        </div>
      </section>

      <!-- 產品 -->
      <section v-if="showProduct" class="list-wrapper">
        <EmptyBlock v-if="showProductEmpty"/>

        <div
          v-else
          class="category__block"
          v-for="item in dataSet.product"
          :key="item.id"
        >
          <div
            v-show="showCategoryLabel(item.list) && !onlyOthersCategory"
            :id="item.name"
            class="category__label"
          >
            {{ item.name }}
          </div>

          <div class="items-list">
            <div
              class="item-card"
              :class="{ select: order.product.id === product.id }"
              v-for="product in item.list"
              :key="product.id"
              @click="toggleActiveStep('unit'), selectProduct(product), (page = 0)"
            >
              <span class="product-class-ticket" v-if="product.ClassTicket">
                {{ product.ClassTicket.name }}
              </span>
              <span class="name">{{ product.name }}</span>
            </div>
          </div>
        </div>
      </section>

      <!-- 堂票 -->
      <section v-if="showClassTicket" class="list-wrapper">
        <div
          class="category__block"
          v-for="item in dataSet.product"
          :key="item.id"
        >
          <div
            v-show="showCategoryLabel(item.list) && !onlyOthersCategory"
            :id="item.name"
            class="category__label"
          >
            {{ item.name }}
          </div>

          <div class="items-list">
            <div
              class="item-card class-ticket"
              v-for="classTicket in item.list"
              :key="classTicket.id"
              @click="toggleActiveStep('unit'), selectProduct(classTicket), (page = 0)"
            >
              <span class="class-ticket">
                剩餘：{{ classTicket.availableTimes - classTicket.usedTimes }}次
              </span>
              <span class="name">{{ classTicket.name }}</span>
            </div>
          </div>
        </div>
      </section>

      <!-- 人員 -->
      <section v-if="showUnit" class="list-wrapper">
        <div
          class="category__block"
          v-for="item in dataSet.unit"
          :key="item.id"
        >
          <div
            v-show="showCategoryLabel(item.list) && !onlyOthersCategory"
            :id="item.name"
            class="category__label"
          >
            {{ item.name }}
          </div>

          <div class="items-list">
            <div
              class="item-card"
              :class="{ select: order.unit.id === unit.id }"
              v-for="unit in item.list"
              :key="unit.id"
              @click="selectUnit(unit), (page = 0)"
            >
              <span class="name">{{ unit.name }}</span>
            </div>
          </div>
        </div>
      </section>

      <!-- 當前選擇提示 -->
      <section class="select-hint">
        <div
          class="select-hint-card"
          v-if="!isEmpty(order.category)"
          @click="resetOrderData(['category', 'product', 'unit'])"
        >
          <span>類別 | {{ order.category.name }}</span>
        </div>
        <div
          class="select-hint-card"
          v-if="!isEmpty(order.product)"
          @click="resetOrderData(['product', 'unit'])"
        >
          <span>產品 | {{ order.product.name }}</span>
        </div>
        <div
          class="select-hint-card"
          v-if="!isEmpty(order.unit)"
          @click="resetOrderData(['unit'])"
        >
          <span>人員 | {{ order.unit.name }}</span>
        </div>
      </section>

      <section class="right-footer">
        <section class="flex-between">
          <el-form class="footer-form">
            <el-form-item class="m-0">
              <div class="flex flex-col">
                <span class="label">數量</span>
                <el-input
                  type="number"
                  min="1"
                  @input="checkQuantity"
                  :disabled="disableQuantity()"
                  v-model.number="order.product.count"
                  style="width: 110px"
                  placeholder="0"
                />
              </div>
            </el-form-item>
            <el-form-item class="m-0">
              <div class="flex flex-col">
                <span class="label">產品單價</span>
                <el-input
                  :disabled="isEmpty(order.product) || order.category?.id === 'classTicket'"
                  v-model.number="order.product.defaultPrice"
                  style="width: 110px"
                  placeholder="0"
                />
              </div>
            </el-form-item>
            <el-form-item v-if="order.product && order.product.coinAmount" class="m-0">
              <div class="flex flex-col">
                <span>儲值金總額</span>
                <div class="total-price">${{ calculatedCoinAmount }}</div>
              </div>
            </el-form-item>
            <el-form-item v-if="order.product && order.product.coinAmount" class="m-0">
              <div class="flex flex-col">
                <span>產品總售價</span>
                <div class="total-price">${{ (order.product.count * order.product.defaultPrice).toFixed(0) }}</div>
              </div>
            </el-form-item>
          </el-form>

          <el-button
            type="primary"
            :disabled="disablePushToStack"
            style="align-self: flex-end"
            @click="toggleActiveStep('category'); pushOrderStack()"
          >
            新增明細
          </el-button>
        </section>
      </section>
    </div>

    <!-- // 右邊視圖欄 - Payment // -->
    <div
      class="right-panel"
      v-if="
        (meta.type === 'payment' && !salesConfig.identityMode) ||
          (meta.type === 'payment' && !selectIdentity && salesConfig.identityMode)
      "
    >
      <div class="flex-1 overflow-y-auto">
        <!-- 優惠 -->
        <section class="payment-options">
          <div class="cate-title">優惠</div>
          <div class="option-items">
            <!-- 折扣 -->
            <div
              class="item-card"
              :class="{
                select: discountStack.find((item) => item.id === discount.id),
              }"
              v-for="discount in dataSet.discount[page]"
              :key="discount.id"
              @click="discount.type === 'free' ? setCustom(discount) : selectDiscount(discount)"
            >
              <span
                class="name"
                :class="{ 'icon-marketing-league': discount.isAllianceActivity }"
                >{{ discount.name }}</span
              >
            </div>
          </div>
          <div
            class="pagination-container"
            style="margin-top: 3px"
            v-if="showPagination(dataSet.discount)"
          >
            <i
              class="el-icon-arrow-left page-btn"
              @click="pageChange('sub', dataSet.discount)"
            />
            <i
              class="el-icon-arrow-right page-btn"
              @click="pageChange('add', dataSet.discount)"
            />
          </div>
        </section>
        <RebateBlock 
          v-if="useShopRebate"
          :totalPrice="discountShow.left"
          :discountStack="discountStack" 
          :rebateWalletBalance="rebateWalletBalance" 
          @use="onUseRebateWallet"
        />
        <!-- 其他付款方式 -->
        <section class="payment-options">
          <div class="cate-title">其他付款方式</div>
          <div class="option-items">
            <div
              class="item-card"
              v-show="payWayShow(payment.type)"
              :class="{
                select: paymentStack.find((item) => item.type === payment.type),
              }"
              v-for="payment in salesConfig.paymentTypes"
              :key="payment.type"
              @click="openPaymentDialog(payment)"
            >
              <span class="name">{{ payment.name }}</span>
            </div>
          </div>
        </section>
      </div>


      <!-- 折扣 -->
      <section class="discount-detail">
        <div class="item">
          <span class="tag">折扣</span>
          <span>{{ discountShow.label || '無' }}</span>
        </div>
        <div class="item" v-if="useShopRebate">
          <span class="tag">回饋金折抵</span>
          <span>{{rebateUse ? '-' : ''}}{{ rebateUse || '無' }}</span>
        </div>
        <div class="item">
          <span class="tag">其他付款方式</span>
          <span>{{ paymentShow || '無' }}</span>
        </div>
      </section>

      

      <section class="footer-count">
        <section class="flex items-center">
          <span style="font-size: 16px;">應收金額</span>
          <span class="count">$ {{ paymentList.left }}</span>
        </section>
        <section class="flex items-start h-full" v-if="useShopPoint || useSalesCashback"
        :class="{
          'items-start':  useSalesCashback,
          'items-center': !useSalesCashback
        }"
        >
          <span style="font-size: 16px; margin-right: 10px">本次可獲得回饋</span>
          <div class="flex flex-col">
            <div v-if="useShopPoint" class="flex" style="gap: 12px">
              <span>點數：{{ pointRewardActivity }}點</span>
              <span>|</span>
              <span>檔期回饋金：{{ cashbackRewardActivity }}</span>
            </div>
            <div v-if="useSalesCashback" class="flex items-center">
              <span style="margin-right: 10px" >回饋金：(系統預設回饋：$ {{ systemRebate }})</span>
              <el-form ref="rebateAddForm" :show-message="false" hide-required-asterisk :model="{customRebate}">
                <el-form-item style="margin-bottom: 0" :rules="[noEmptyRules()]" prop="customRebate">
                  <el-input v-model="customRebate" clearable type="number" style="min-width: 83px; max-width: 100px" />
                </el-form-item>
              </el-form>
            </div>
          </div>
        </section>
      </section>
    </div>

    <!-- Dialog -->
    <el-dialog :visible.sync="paymentDialog" width="420px" :close-on-click-modal="false" @close="resetForm">
      <div slot="title" v-if="selectPayment">
        <div>{{ selectPayment.name }}</div>
        <!-- <el-divider /> -->
      </div>

      <el-form v-if="selectPayment" ref="paymentDialog" :model="paymentForm">
        <el-form-item>
          <div class="flex flex-col">
            <span>使用方式</span>
            <el-radio-group v-model="paymentForm.payAll" @change="payChange">
              <el-radio :label="true">全額金額</el-radio>
              <el-radio :label="false">部分金額</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item
          prop="amount"
          :rules="[
            {
              type: 'number',
              min: 0,
              max: paymentList.left,
              message: `數值介於${0}與${paymentList.left}之間`,
            },
          ]"
        >
          <div class="flex flex-col">
            <span>折抵</span>
            <el-input
            style="width: 100%"
              v-model.number="paymentForm.amount"
              @change="
                paymentForm.amount < paymentList.left
                  ? (paymentForm.payAll = false)
                  : (paymentForm.payAll = true)
              "
              type="number"
              class="w-full"
              placeholder="請輸入金額"
            />
          </div>
        </el-form-item>

        <el-form-item>
          <div class="flex flex-col" v-if="selectPayment.type === 'wallet'">
            <span>餘額</span>
            <span>{{ member.Wallet.balance - paymentForm.amount }}</span>
          </div>
        </el-form-item>
      </el-form>

      <section slot="footer">
        <el-button type="primary" @click="pushPaymentStack()">確認</el-button>
      </section>
    </el-dialog>

    <!-- Dialog -->
    <el-dialog :visible.sync="payConfirm" width="300px" :close-on-click-modal="false">
      <div class="flex flex-col">
        <div class="check-icon">
          <i class="el-icon-circle-check" />
          <span class="text-center" style="font-size: 18px">應收金額</span>
          <span class="amount">$ {{ paymentList.left }}</span>
        </div>
        <el-button
          ref="createRecordBtn"
          type="primary"
          @click="createSalesRecord"
          >出帳</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      v-if="selectedDiscountCustom"
      :visible.sync="showDiscountCustom"
      :close-on-click-modal="false"
    >
      <template #title>
        <div>{{ selectedDiscountCustom.name }}</div>
        <el-divider />
      </template>

      <el-form class="custom-form" ref="discountCustomRef" :model="customForm">
        <el-form-item
          label="自定義優惠金額"
          prop="amount"
          :rules="[
            {
              type: 'number',
              min: 0,
              message: '請輸入優惠金額',
            }
          ]"
        >
          <el-input v-model.number="customForm.amount"></el-input>
        </el-form-item>
      </el-form>

       <template #footer>
        <el-button type="primary" :disabled="!customForm.amount" @click="setCustomAmount">使用</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import RebateBlock from './components/RebateBlock.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { FindMember } from '@/api/member'
import {
  GetSalesCategory,
  GetSalesCategoryCount,
  FindSalesCategory,
  GetSalesUnit,
  GetSalesDiscount,
  CreateSalesRecord,
  GetRecordCreationConfig,
} from '@/api/sales'
import { GetClassTicketRecord } from '@/api/classTicket'
import { mapGetters } from 'vuex'
import { sliceIntoChunks, isEmptyObj, getAllDataFromApi } from '@/utils/helper'
import formUtils from '@/utils/form'
import { calcCashDiscount, calcRatioDiscount, unDiscountTotalItemPrice, calcRebateDiscount } from './utils'
import { checkUserFeature } from '@/store/modules/permission'
import { FindMemberRebateWallet } from '@/api/rebate'
import { get, find, toNumber } from 'lodash'
import { noEmptyRules } from '@/validation'
import { RewardActivityPreCalculateReward } from '@/api/rewardActivity'
import dayjs from 'dayjs'
import { computed, ref } from 'vue'
import { usePermissions } from '@/use/permissions'

const warnUnload = (e) => {
  e.preventDefault()
  return (e.returnValue = '')
}
// TODO 修正 item-card 大小
// TODO item-card select style

// orderStack - 所有已選取後的商品陣列

const featureKeys = {
  classTicket: 'admin.classTicket.page',
  walletPayment: 'admin.salesRecord.create.walletPayment',
  shopRebate: 'admin.salesCashback.page',
  shopPoint: 'admin.shopPoint.page',
  salesCashback: 'admin.salesCashback.use',
}

export default {
  name: 'SalesRecordCreate',
  components: { EmptyBlock, RebateBlock },
  setup (props, { emit }) {
    const { checkAction } = usePermissions()

    const config = ref({
      id: '',
      shopId: '',
      identityMode: false,
      paymentTypes: [], // 付款方式
      identities: [], // 來客類型貼標
      updatedAt: '',
    })
    const nullMember = ref(false)
    const salesConfig = computed(() => {
      return get(config.value, 'SalesConfig', {})
    })
    const useClassTicketFeature = computed(() => checkAction(featureKeys.classTicket))
    const useWalletPayment = computed(() => checkAction(featureKeys.walletPayment))
    const useShopRebate = computed(() => checkAction(featureKeys.shopRebate) && get(config.value, 'SalesCashback.enabled') && !salesConfig.value.identityMode && !nullMember.value)
    const useShopPoint = computed(() => checkAction(featureKeys.shopPoint))
    const useSalesCashback = computed(() => checkAction(featureKeys.salesCashback) && get(config.value, 'SalesCashback.enabled'))

    return {
      config,
      salesConfig,
      nullMember,
      useClassTicketFeature,
      useWalletPayment,
      useShopRebate,
      useShopPoint,
      useSalesCashback,
    }
  },
  data: () => ({
    loading: {
      page: false,
      rightPanel: false,
    },

    shopRebate: { id: null },
    salesRebateConfig: {},
    member: {
      UserInfo: {
        name: '',
        phone: '',
        birthday: '',
        address: '',
        gender: '',
      },
      Wallet: {
        balance: 0, // 儲值金餘額
      },
    }, // 會員資訊
    rebateUse: 0,
    customRebate: 0,
    rebateWalletBalance: 0,
    identity: { name: '過路客' },
    selectIdentity: true,
    availableClassTicket: 0,
    page: 0,

    tempDataSet: {
      category: [],
      product: [],
      unit: [],
    },

    dataSet: {
      category: [], // 銷售類別選項
      product: [], // 銷售產品/課程/堂票選項 (可以不綁定銷售人員)
      unit: [], // 銷售人員選項
      discount: [], // 折價類別選項
    },

    order: {
      category: {},
      product: {},
      unit: {},
    }, // 當前選擇的消費項目
    lastUnit: null,

    orderStack: {}, // 所有消費項目
    paymentStack: [], // 所有付款方式
    discountStack: [], // 所有優惠項目

    walletCoin: {
      use: '',
    },

    selectPayment: null,
    payConfirm: false,
    paymentDialog: false,
    paymentForm: {
      payAll: true,
      amount: '',
    },

    showDiscountCustom: false,
    selectedDiscountCustom: null,
    customForm: {
      amount: 0,
    },

    activeStep: 'category',
    activeGroup: '',
    availableGroupList: [],
    availableOnlyOthers: false,

    //  當前結帳訂單可獲得銷售回饋點數，type: array
    productRewardActivity: [],
  }),
  computed: {
    ...mapGetters(['shop', 'sidebar', 'userPlanFeature', 'userFeatures']),
    isMember () {
      return this.$route.params.isMember
    },
    isMobile () {
      return this.$store.state.app.isMobile
    },
    meta () {
      return this.$route.meta
    },
    recordAt () {
      const dateValue = get(this.$route, 'query.recordAt') || undefined

      return toNumber(dateValue)
    },
    searchPhone () {
      return this.$route.params.phone
    },

    identityMode () {
      return this.salesConfig.identityMode
    },

    totalPaymentStackAmount () {
      return this.paymentStack.reduce((previous, current) => previous + current.amount, 0)
    },

    // ANCHOR 組合最後創建訂單的商品列表資料形式
    itemList () {
      const stack = this.orderStack
      const list = []
      for (const unit in stack) {
        stack[unit].forEach((order) => {
          list.push({
            salesUnitId: order.unit.id || undefined,
            salesProductId:
              order.category.id === 'classTicket'
                ? undefined
                : order.product.id,
            salesCategoryId:
              order.category.id === 'classTicket'
                ? undefined
                : order.category.id,
            classTicketRecordId:
              order.category.id === 'classTicket'
                ? order.product.id
                : undefined,
            price: order.product.defaultPrice || 0,
            quantity: order.product.count,
          })
        })
      }

      return list
    },

    compactDiscounts () {
      const stack = this.discountStack
      const list = []
      stack.forEach((item) => {
        if (item.id === 'rebate') {
          list.push({ useCashbackAmount: Number(item.use) })
        } else {
          list.push({
            [item.isAllianceActivity
              ? 'voucherHistoryId'
              : 'salesDiscountId']: item.id,
            freeAmount: item.cashAmount,
          })
        }
      })
      return list
    },

    rebateUseAmount () {
      const amount = get(find(this.discountStack, { id: 'rebate' }), 'use') || 0
      return Number(amount)
    },

    paymentList () {
      const stack = this.paymentStack
      const list = []

      let left = this.discountShow.left - Number(this.rebateUseAmount)

      stack.forEach((item) => {
        if (left - item.amount > 0) left -= item.amount
        else left = 0

        list.push({
          type: item.type,
          amount: item.amount,
        })
      })
      list.push({
        type: 'cash',
        amount: left,
      })

      let total = 0
      list.forEach((item) => (total += item.amount))
      return { list, total, left }
    },

    //= > 類別顯示控制
    showCategory () {
      // 類別為空時
      return isEmptyObj(this.order.category)
    },

    //= > 一般產品顯示控制
    showProduct () {
      // 類別不為空，產品為空，且類別不為使用堂票
      // 類別不為空，產品不為空，但無需人員，且類別不為使用堂票
      const category = isEmptyObj(this.order.category)
      const product = isEmptyObj(this.order.product)
      const needUnit = this.order.product.needUnit
      const isClassTicket = this.order.category.id !== 'classTicket'
      return (
        (!category && product && isClassTicket) ||
        (!category && !product && !needUnit && isClassTicket)
      )
    },

    //= > 堂票顯示控制
    showClassTicket () {
      // 類別不為空，產品為空，且類別為使用堂票
      const category = isEmptyObj(this.order.category)
      const product = isEmptyObj(this.order.product)
      const identityMode = this.identityMode
      const isClassTicket = this.order.category.id === 'classTicket'
      return !identityMode && !category && product && isClassTicket
    },

    //= > 人員顯示控制
    showUnit () {
      // 1. 類別不為空，產品不為空，且需要人員
      // 2. 類別不為空，產品不為空，但為堂票
      const category = isEmptyObj(this.order.category)
      const product = isEmptyObj(this.order.product)
      const needUnit = this.order.product.needUnit
      const isClassTicket = this.order.category.id === 'classTicket'
      return (
        (!category && !product && needUnit) ||
        (!category && !product && isClassTicket)
      )
    },

    disablePushToStack () {
      let disable = false
      // 1. 產品需要人員，但人員為空
      // 2. 產品無需人員，但產品為空
      // 3. 產品為堂票，但人員為空
      const needUnit = this.order.product.needUnit
      const unit = isEmptyObj(this.order.unit)
      const product = isEmptyObj(this.order.product)
      const isClassTicket = this.order.category.id === 'classTicket'

      if (needUnit && unit) disable = true
      if (!needUnit && product) disable = true
      if (isClassTicket && unit) disable = true
      if (this.order.product.count < 1) disable = true

      return disable
    },

    paymentShow () {
      const stack = this.paymentStack
      const list = []
      stack.forEach((item) => {
        list.push(`${item.name}支付${item.amount}元`)
      })

      return list.join('、')
    },

    systemRebate () {
      if (!this.useSalesCashback) return 0
      // 舊機制結果
      const old = calcRebateDiscount({
        config: get(this.config, 'SalesCashback', {}),
        orderStack: this.orderStack,
        paymentStack: this.paymentStack,
        totalPrice: this.paymentList.left,
      })
      return old
      // return old + this.cashbackRewardActivity
      // return this.cashbackRewardActivity
    },

    // ANCHOR 優惠計算邏輯
    discountShow () {
      const stack = this.discountStack

      let total = this.totalItemPrice
      let totalDiscounts = 0
      const showLabels = []

      stack.forEach(discount => {
        const type = discount.type
        if (type === 'cash' || type === 'free') {
          if (total - discount.cashAmount > 0) {
            showLabels.push(`${discount.name}-${discount.cashAmount}`)
            total -= discount.cashAmount
            totalDiscounts += discount.cashAmount
          } else if (total - discount.cashAmount <= 0) {
            totalDiscounts += total
            showLabels.push(`${discount.name}-${total}`)
            total = 0
          }
        }

        if (type === 'percent') {
          const price = Math.round(total - Math.round(total * discount.percentAmount))
          showLabels.push(`${discount.name}-${price}`)
          if (price > 0) {
            total -= price
            totalDiscounts += price
          } else if (price <= 0) total = 0
        }
      })

      return {
        label: showLabels.join('、'),
        left: total,
        totalDiscounts,
      }
    },

    //= > 總金額(未折任何折扣)
    totalItemPrice () {
      return unDiscountTotalItemPrice(this.orderStack)
    },

    // 購買儲值金總額
    calculatedCoinAmount () {
      // 有些商品購買會贈送儲值金 (以購買商品的形式)
      // 因此 product.coinAmount * product.count
      const product = this.order.product
      return product ? product.coinAmount * product.count : 0
    },

    showProductEmpty () {
      if (this.dataSet.product.length === 0) return true
      else if (this.dataSet.product.length === 1 && this.dataSet.product[0].list.length === 0) return true
      else return false
    },

    hiddenGroupNav () {
      return !(this.availableGroupList && this.availableGroupList.length) || (this.availableGroupList.length === 1 && this.availableGroupList[0].id === 'others') || (!this.isEmpty(this.order.product) && !this.order.product.needUnit)
    },

    onlyOthersCategory () {
      return (this.dataSet[this.activeStep].length && (this.dataSet[this.activeStep].length === 1 && this.dataSet[this.activeStep].find(item => item.id === 'others'))) || (!this.isEmpty(this.order.product) && !this.order.product.needUnit)
    },

    pointRewardActivity () {
      const totalPoint = this.productRewardActivity.reduce((prev, curr) => {
        if (curr.rewardType === 'POINT') {
          return prev + curr.rewardAmount
        }
        return prev
      }, 0)
      return totalPoint
    },
    cashbackRewardActivity () {
      const total = this.productRewardActivity.reduce((prev, curr) => {
        if (curr.rewardType === 'CASHBACK') {
          return prev + curr.rewardAmount
        }
        return prev
      }, 0)
      return total
    },
  },

  watch: {
    compactDiscounts () {
      // 如果折扣有變，重置所有付款方式，重新付款
      this.paymentStack = []
    },

    activeStep (current) {
      this.toggleActiveStep(current)
      this.getAvailableGroupList(current)
    },

    systemRebate (amount) {
      this.customRebate = amount
    },
    paymentList () {
      if (this.member.id) {
        this.getProductDiscount()
      }
    },
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', warnUnload, { capture: true })
  },

  async mounted () {
    window.addEventListener('beforeunload', warnUnload, { capture: true })

    this.loading.page = true
    if (this.meta.type === 'payment' && isEmptyObj(this.orderStack)) { this.$router.push('/sales/sales-record-login') }
    await this.findMember()
    await this.getRecordCreationConfig()
    if (this.useClassTicketFeature) await this.getClassTicketRecord(true)

    if (this.useShopRebate) {
      await Promise.all([
        this.getSalesCategory(),
        this.findMemberRebateWallet(),
      ])
    } else {
      await this.getSalesCategory()
    }
    this.loading.page = false
  },

  methods: {
    noEmptyRules,
    async handlePayConfirm () {
      if (this.useShopRebate) {
        if (!(await formUtils.checkForm(this.$refs.rebateAddForm))) {
          this.$message.warning('請填寫可獲得回饋金金額！')
          return
        }
      }
      this.payConfirm = true
    },
    // 回饋金
    async findMemberRebateWallet () {
      if (!this.member.id) return
      const [res, err] = await FindMemberRebateWallet({ shopId: this.shop, memberId: this.member.id })
      if (err) return (window.$message.error(err))
      this.rebateWalletBalance = res.balance
    },

    onUseRebateWallet (useAmount) {
      this.rebateUse = useAmount || null
      this.selectDiscount({
        id: 'rebate',
        use: useAmount,
      })
    },
    checkQuantity (quantity) {
      if (this.order.category.id === 'classTicket') {
        const availableTimes =
          this.order.product.availableTimes - this.order.product.usedTimes
        // eslint-disable-next-line no-dupe-else-if
        if (availableTimes && quantity > availableTimes) { this.order.product.count = availableTimes } else if (availableTimes && quantity > availableTimes) { this.order.product.count = 0 } else this.order.product.count = quantity
      } else {
        if (quantity < 0) this.order.product.count = 0
        else this.order.product.count = quantity
      }
    },
    disableQuantity () {
      return (
        this.isEmpty(this.order.product) ||
        (this.order.product.ClassTicketId &&
          this.order.category.id !== 'classTicket')
      )
    },
    isEmpty (obj) {
      return isEmptyObj(obj)
    },

    showPagination (arr) {
      return arr.length > 1
    },

    pageChange (mode, arr) {
      const max = arr.length
      const cur = this.page
      if (mode === 'sub') {
        if (cur > 0) this.page -= 1
      }

      if (mode === 'add') {
        if (cur < max - 1) this.page += 1
      }
    },

    resetForm () {
      this.paymentForm.amount = 0
      this.paymentForm.payAll = true
    },

    openPaymentDialog (payment) {
      const isExist = this.paymentStack.find(
        (item) => item.type === payment.type,
      )
      if (isExist) {
        this.paymentStack = this.paymentStack.filter(
          (item) => item.type !== payment.type,
        )
        return
      }
      this.selectPayment = payment
      this.paymentDialog = true
      if (payment.type !== 'wallet') { this.paymentForm.amount = this.paymentList.left }
      if (payment.type === 'wallet') {
        const needPay = this.paymentList.left
        const balance = this.member.Wallet.balance

        if (needPay < balance) this.paymentForm.amount = needPay
        else if (needPay >= balance) this.paymentForm.amount = balance
      }
    },

    // 儲值金付款
    payChange (all) {
      if (this.selectPayment.type === 'wallet') {
        if (all && this.paymentList.left <= this.member.Wallet.balance) {
          this.paymentForm.amount = this.paymentList.left
        } else {
          this.paymentForm.amount = this.member.Wallet.balance
        }
      } else {
        if (all) this.paymentForm.amount = this.paymentList.left
      }

      if (!all) this.paymentForm.amount = 0
    },

    async pushPaymentStack () {
      if (!(await formUtils.checkForm(this.$refs.paymentDialog))) return
      if (Number(this.paymentForm.amount) === 0) {
        this.paymentDialog = false
        return
      }
      this.$set(this.selectPayment, 'amount', this.paymentForm.amount)
      this.paymentStack.push(this.selectPayment)
      this.paymentDialog = false
      this.resetForm()
    },

    // 其他付款方式顯示控制
    payWayShow (type) {
      if (type === 'cash') return false
      const isWallet = type === 'wallet'
      if ((this.nullMember && isWallet) || (this.identityMode && isWallet)) { return false } else return true
    },

    //= > 重置當前指定訂單資料
    resetOrderData (cate) {
      if (cate === 'all') {
        Object.keys(this.order).map((key) => (this.order[key] = {}))
        return
      }
      cate.map((key) => (this.order[key] = {}))
      this.page = 0
    },

    //= > 重置訂單明細列表
    resetOrderStack () {
      // this.$router.go(-1)
      this.resetOrderData('all')
      this.orderStack = {}
      this.discountStack = []
      this.paymentStack = []
    },

    backToCreate () {
      this.$router.go(-1)
      this.discountStack = []
      this.paymentStack = []
      this.productRewardActivity = []
    },

    async findMember () {
      if (this.salesConfig.identityMode) return

      const id = this.$route.params.id

      if (!this.isMember) {
        this.nullMember = true
        this.member = {
          UserInfo: {
            name: '非會員',
            phone: id,
          },
        }
        return
      }

      if (id) {
        try {
          const member = await FindMember({
            shopId: this.shop,
            id: id,
          })

          this.member = member
          this.nullMember = false
        } catch (error) {
          this.nullMember = true
          this.member = {
            UserInfo: {
              name: '非會員',
              phone: id,
            },
          }
        }
      } else {
        this.nullMember = true
        this.member = {
          UserInfo: {
            name: '非會員',
            phone: id,
          },
        }
      }
    },

    async getRecordCreationConfig () {
      try {
        const [res, err] = await GetRecordCreationConfig({ shopId: this.shop })
        if (err) return (this.$message.error(err))
        this.config = res
        if (!this.useWalletPayment) this.config.SalesConfig.paymentTypes = this.salesConfig.paymentTypes.filter(p => p.type !== 'wallet')
      } catch (error) {
        console.log(error)
      }
    },

    async getSalesCategoryCount () {
      try {
        return await GetSalesCategoryCount({
          shopId: this.shop,
        })
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    //= > 取得銷售類別
    async getSalesCategory () {
      try {
        this.loading.page = true
        const max = await this.getSalesCategoryCount()
        const config = {
          start: 0,
          limit: 100,
          shopId: this.shop,
        }
        const category = await getAllDataFromApi(max, GetSalesCategory, config)
        category.sort((pre, next) => {
          if (pre.order > next.order) return 1
          return -1
        })

        if (
          !this.identityMode &&
        !this.nullMember &&
        this.availableClassTicket > 0
        ) {
          category.push({
            name: '使用堂票',
            id: 'classTicket',
          })
        }

        this.tempDataSet.category = category

        this.getAvailableGroupList('category')
        this.dataSet.category = this.getSortedList(category)
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    //= > 取得使用者擁有堂票
    async getClassTicketRecord (count = false) {
      if (this.nullMember || this.identityMode) return
      let res = await GetClassTicketRecord({
        shopId: this.shop,
        MemberId: this.member.id,
      })
      // 狀態為可使用且還有使用次數
      res = res.filter(
        (item) => item.status === 'open' && item.availableTimes > 0,
      )
      res.forEach((item) => this.$set(item, 'count', 1))
      res.forEach((item) => this.$set(item, 'defaultPrice', 0))
      res.forEach((item) => this.$set(item, 'needUnit', true))

      if (count) {
        this.availableClassTicket = res.length
        return
      }

      this.tempDataSet.product = res

      this.getAvailableGroupList('product')
      this.dataSet.product = this.getSortedList(res)
    },

    //= > 取得銷售優惠
    async getSalseDiscount () {
      let res = await GetSalesDiscount({ shopId: this.shop })
      const phone =
        this.member && this.member.UserInfo && this.member.UserInfo.phone
      const resMarketingLeagueDicount = await this.$store.dispatch(
        'marketing-league/GET_USER_MARKETING_LEAGUE_DISCOUNT',
        { phone },
      )
      res = res.concat(resMarketingLeagueDicount)
      const limit = 6
      if (res.length <= limit) {
        this.dataSet.discount = []
        this.dataSet.discount[0] = res
      } else if (res.length > limit) { this.dataSet.discount = sliceIntoChunks(res, limit) }
    },

    //= > 選擇類別
    async selectCategory (category) {
      this.loading.rightPanel = true
      try {
        if (category.id === 'classTicket') {
          this.order.category = category
          await this.getClassTicketRecord()
          this.loading.rightPanel = false
          return
        }

        const res = await FindSalesCategory({
          shopId: this.shop,
          id: category.id,
        })

        this.order.category = res

        // 非會員 > 過濾有綁定堂票的產品
        if (this.nullMember || this.identityMode) {
          res.SalesProducts = res.SalesProducts.filter(
            (item) => !item.ClassTicket,
          )
        }

        // 會員 > 過濾已過期、已取消堂票
        if (!this.nullMember || !this.identityMode) {
          res.SalesProducts = res.SalesProducts.filter((item) => {
            if (
              item.ClassTicket &&
              item.ClassTicket.status === 'open' &&
              !item.ClassTicket.isRemove
            ) {
              this.loading.rightPanel = false
              return true
            }
            if (!item.isRemove) {
              this.loading.rightPanel = false
              return true
            }
          })
        }

        // 類別下的產品
        res.SalesProducts.sort((pre, next) => {
          this.loading.rightPanel = false
          if (pre.order > next.order) return 1
          return -1
        })

        res.SalesProducts = res.SalesProducts.filter(item => this.member.UserInfo.id ? item : !item.useCoin)

        this.tempDataSet.product = res.SalesProducts

        this.getAvailableGroupList('product')
        this.dataSet.product = this.getSortedList(res.SalesProducts)
      } catch (error) {
        console.log(error)
      }
      this.loading.rightPanel = false
    },

    //= > 選擇產品
    async selectProduct (product) {
      try {
        this.order.product = product
        this.$set(this.order.product, 'count', 1)
        const units = await GetSalesUnit({ shopId: this.shop })
        units.sort((pre, next) => {
          if (pre.order > next.order) return 1
          return -1
        })

        this.tempDataSet.unit = units

        this.getAvailableGroupList('unit')
        this.dataSet.unit = this.getSortedList(units)

        if (this.lastUnit && product.needUnit) this.order.unit = this.lastUnit
      } catch (error) {
        console.log(error)
      }
    },

    //= > 選擇人員
    async selectUnit (unit) {
      this.order.unit = unit
    },

    //= > 添加明細
    pushOrderStack () {
      const order = JSON.parse(JSON.stringify(this.order))
      let unit = ''
      if (isEmptyObj(order.unit)) {
        unit = '無銷售人員'
        order.unit = { name: '無銷售人員', id: undefined }
      } else {
        unit = order.unit.name
      }

      if (unit in this.orderStack) this.orderStack[unit].push(order)
      else {
        this.$set(this.orderStack, unit, [])
        this.orderStack[unit].push(order)
      }
      if (unit !== '無銷售人員') this.lastUnit = order.unit
      this.resetOrderData('all')
    },

    //= > 移除指定明細
    removeOrder (unit, index) {
      this.orderStack[unit].splice(index, 1)
      if (this.orderStack[unit].length === 0) delete this.orderStack[unit]
    },

    async getProductDiscount () {
      this.loading.rightPanel = true
      const orderData = this.itemList
      const productItems = orderData.map((item) => {
        return {
          sourceItemType: 'salesProduct',
          sourceItemId: item.salesProductId,
          price: item.price,
          quantity: item.quantity,
        }
      })
      const totalPaidAmount = this.paymentList.left

      const [res, err] = await RewardActivityPreCalculateReward({
        shopId: this.shop,
        memberId: this.member.id || null,
        source: 'SALE_RECORD',
        paidAmount: (totalPaidAmount + this.totalPaymentStackAmount),
        items: [...productItems],
      })
      this.loading.rightPanel = false
      if (err) {
        this.$message.error(err)
        return
      }
      this.productRewardActivity = res
    },

    //= > 前往付款頁
    async goPayment () {
      await this.getSalseDiscount()
      if (this.useShopPoint && this.member.id) {
        this.getProductDiscount()
      }

      this.$router.push({
        name: 'SalesRecordPayment',
        query: {
          recordAt: this.recordAt,
        },
      })
    },

    selectDiscount (discount) {
      if (this.discountStack.find((item) => item.id === discount.id)) {
        const index = this.discountStack.indexOf(discount)
        this.discountStack.splice(index, 1)
        return
      }
      this.discountStack.push(discount)
    },

    async createSalesRecord () {
      this.$refs.createRecordBtn.loading = true
      // const useRebate = this.useShopRebate && this.shopRebate.id
      const useRebate = this.useShopRebate
      let totalAddCashback
      if (useRebate) {
        totalAddCashback = this.customRebate === '' ? Number(this.systemRebate) : Number(this.customRebate)
      }
      try {
        this.postTrackingData()
        let sellAt
        if (this.recordAt) sellAt = dayjs(this.recordAt).toDate()
        await CreateSalesRecord({
          shopId: this.shop,
          memberId:
            !this.nullMember && !this.identityMode ? this.member.id : undefined,
          identity: this.salesConfig.identityMode ? this.identity.code : undefined,
          phone: this.member.id ? undefined : this.member.UserInfo.phone,
          items: this.itemList,
          discounts: this.compactDiscounts,
          payments: this.paymentList.list,
          totalItemsPrice:
          this.rebateUseAmount +
            Number(this.discountShow.totalDiscounts) +
            Number(this.paymentList.total),
          totalDiscountPrice: Number(this.discountShow.totalDiscounts) + this.rebateUseAmount,
          totalPaymentPrice: Number(this.paymentList.total),
          totalAddCashback,
          sellAt,
        })
        this.resetOrderStack()
        this.$message.success('建立銷售紀錄成功 !')
        if (this.recordAt) this.$router.push({ name: 'SalesRecordLoginCrossDay' })
        else this.$router.push({ name: 'SalesRecordLogin' })
        this.$refs.createRecordBtn.loading = false
      } catch (error) {
        console.log(error)
        this.$message.error(error)
        this.$refs.createRecordBtn.loading = false
      }
    },
    postTrackingData () {
      const { member, orderStack, paymentList, discountStack } = this
      this.$store.dispatch('marketing-league/POST_TRACKING_DATA', {
        member,
        orderStack,
        paymentStack: paymentList.list,
        discountStack,
      })
    },

    setCustom (discount) {
      if (discount.cashAmount) {
        this.selectedDiscountCustom = null
        discount.cashAmount = ''
        this.selectDiscount(discount)
        return
      }

      this.selectedDiscountCustom = discount
      this.showDiscountCustom = true
    },

    setCustomAmount () {
      this.selectedDiscountCustom.cashAmount = Number(this.customForm.amount)
      this.showDiscountCustom = false
      this.selectDiscount(this.selectedDiscountCustom)

      this.$refs.discountCustomRef.resetFields()
      this.resetCustom()
    },

    resetCustom () {
      this.selectedDiscountCustom = null
      this.customForm = {
        amount: '',
      }
    },

    goMemberProfile (member) {
      if (!member.id) return
      this.$router.push(`/members/member-profile/${member.id}`)
    },

    getAvailableGroupList (type) {
      const targetList = this.tempDataSet[type]
      const availableCategoryIdList = []

      this.availableGroupList.length = 0
      for (let item = 0; item < targetList.length; item += 1) {
        if (targetList[item].SalesGroupId && !availableCategoryIdList.includes(targetList[item].SalesGroupId)) {
          availableCategoryIdList.push(targetList[item].SalesGroupId)
          this.availableGroupList.push(targetList[item].SalesGroup)
        }
      }

      const otherCategoryList = targetList.filter(item => !item.SalesGroupId)
      if (otherCategoryList.length) {
        this.availableGroupList.push({
          id: 'others',
          name: '其他',
          order: 10000,
        })
      }

      this.availableGroupList.sort((a, b) => a.order - b.order)

      this.activeGroup = this.availableGroupList.length ? this.availableGroupList[0].id : ''
    },

    getSortedList (list) {
      return this.availableGroupList.map(item => {
        const groupList = list.filter(subItem => subItem.SalesGroupId === item.id)
        const othersGroupList = list.filter(subItem => !subItem.SalesGroupId)

        return item.id === 'others'
          ? {
            id: item.id,
            name: item.name,
            list: othersGroupList,
          }
          : {
            id: item.id,
            name: item.name,
            list: groupList,
          }
      })
    },

    onCancelPhone () {
      if (this.recordAt) this.$router.push({ name: 'SalesRecordLoginCrossDay' })
      else this.$router.push({ name: 'SalesRecordLogin' })
    },

    toggleActiveStep (step) {
      this.activeStep = step
    },

    toggleActiveGroup (groupId) {
      this.activeGroup = groupId
    },

    showCategoryLabel (list) {
      return list.length
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

section {
  margin: 0;
}

.pagination-container {
  @apply flex justify-end gap-[10px];
}

.page-btn {
  background: #ededed;
  border-radius: 4px;
  padding: 10px;
  transition: ease-in-out 0.15s;
  @apply text-gray-60 cursor-pointer;
}

.sales-record-create {
  padding-bottom: 0 !important;
  @apply flex overflow-hidden;
  @apply absolute top-[80px] bottom-0 left-0 right-0;
  transition: ease-in-out 0.3s;
}

.collapse {
  left: 260px;
}

//!! 左側面板
.left-panel {
  @apply relative border-r-gray-40 border w-[370px];
  display: flex;
  // flex-flow: row wrap;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.left-header {
  @apply flex gap-[10px] p-[20px];

  .member-card {
    user-select: none;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @apply text-gray-80 border border-gray-60;
    border-radius: 4px;
    padding: 3px 4px;
    font-size: 14px;

    &.block {
      color: #ff0000;
      border-color: #ff0000;
      background-color: #FFC5C5;
    }
  }

  .phone-search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // @apply cursor-pointer text-white bg-secondary-100;
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 18px;
  }

  .identity {
    padding: 8px;
    text-align: center;
    font-size: 18px;
  }
}

// 明細列表區域
.orders-list {
  flex: 1;
  overflow-y: auto;
  padding: 0 20px 20px 20px;
  height: 100%;

  // 服務人員明細
  .unit-row {
    @apply flex flex-col py-[10px] px-[9px];
    .unit-name {
      @apply text-normal text-primary-100;
    }

    .unit-order-item {
      position: relative;
      padding: 15px 8px;
      background: white;
      border-radius: 4px;

      .close-icon {
        right: -8px;
        top: -8px;
        @apply cursor-pointer absolute;
        @apply text-gray-60 w-[24px] h-[24px] text-[24px];
      }

      .order-item-count {
        @apply text-gray-80;
        border: solid 1px #dedede;
        border-radius: 4px;
        padding: 1px 7px;
      }
    }
  }
}

.payment-list {
  padding: 20px;
}

// 重置明細、下一步按鈕區域
.left-footer {
  padding: 20px;
  // background: $main-container-bg;
  box-shadow: 0px 1px 9px rgba(1, 10, 38, 0.1);

  .payment-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .btn-container {
    display: flex;
  }
}

//!! 右側面板
.right-panel {
  @apply flex flex-col flex-1 overflow-x-auto ;
  @apply relative top-0 left-0 right-0 bottom-0;
}

.right-header {
  @apply mt-[16px] py-[16px] px-0 border justify-center;
  // margin-top: 16px;
  // padding: 16px 0;
  // border: 1px solid;
  border-color: transparent transparent #EEEEEE transparent;
}

.step-header {
  @apply flex justify-center h-[44px] rounded-sm;
  @apply font-medium bg-gray-20;

  .step-item {
    position: relative;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    color: #767676;
    padding: 0 20px 0 36px;
    border-radius: 0 4px 4px 0;
    pointer-events: none;
    cursor: default;
  }

  .step-item:first-child {
    border-radius: 4px;
  }

  .step-item:last-child {
    padding-right: 36px;
  }

  .step-item:not(:last-child)::after {
    z-index: 1;
    content: "";
    position: absolute;
    top: 6px;
    right: -14px;
    bottom: 0;
    width: 0;
    height: 0;
    border: 16px solid;
    border-color: transparent;
    border-radius: 4px;
    transform: rotate(-45deg);
  }

  .active {
    color: #FFFFFF;
    pointer-events: unset;
    cursor: pointer;
  }

  .done {
    pointer-events: unset;
    cursor: pointer;
  }

  .hide {
    display: none;
  }

  .no-unit {
    padding-right: 36px;

    &::after {
      display: none;
    }
  }
}

.list-wrapper {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.category__label {
  margin-top: 4px;
  font-size: 14px;
  line-height: 24px;
  padding: 0 61px;
}

.select-hint {
  @apply flex gap-[10px];
  padding: 12px 20px 0;
  box-shadow: inset 0px 1px 0px #dedede;

  .select-hint-card {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    color: #767676;
    background: #ededed;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid #767676;
    border-radius: 4px;
  }
}

.right-footer {
  height: 120px;
  padding: 10px 20px 20px;
}

.discount-detail {
  @apply flex flex-col justify-end;
  @apply py-[15px] px-[40px] gap-[20px];
  .tag {
    @apply bg-primary-100 text-white p-[5px] mr-[16px] rounded-[4px];
  }
}

//!! Payment
.footer-count {
  @apply flex items-start justify-start gap-[24px];
  padding: 20px 40px;
  box-shadow: inset 0px 1px 0px #dedede;
  .count {
    @apply text-primary-100 text-[28px] ml-[12px];
  }
}

.payment-options {
  overflow-y: auto;
  padding: 15px 40px;
}

.check-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  @apply text-primary-100;

  .el-icon-circle-check {
    font-size: 32px;
    margin-bottom: 10px;
  }

  .amount {
    font-size: 24px;
    @apply text-primary-100 mt-[20px] mb-[57px] font-bold;
  }
}

.item-block {
  width: 80%;
  padding: 2px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-marketing-league {
  &::before {
    content: '';
    background-image: url(../../../assets/icon/marketing-league.svg);
    background-position: bottom left;
    background-size: contain;
    vertical-align: bottom;
    width: 24px;
    display: inline-block;
    height: 24px;
    margin-right: 8px;
  }
}

// .custom-form {
//   display: flex;

//   .el-input__inner {
//     width: 100%;
//   }
// }

.group-wrapper {
  @apply flex justify-center my-[16px] px-[16px];
}

.tab-wrapper {
  @apply flex gap-x-[4px] py-[4px] px-[6px];
  @apply bg-gray-20 rounded-sm;
}

.tab__item {
  @apply py-[4px] px-[12px] bg-transparent rounded-sub;
  @apply text-normal leading-[24px] text-gray-60 cursor-pointer;

  &.active {
    @apply text-white;
  }
}

.total-price {
  @apply mt-[8px] text-xl leading-[30px];
  line-height: 30px;
}

.footer-form {
  @apply flex gap-[20px];
  .label {
    @apply text-normal;
  }
}
</style>
