<template>
  <el-dialog
    title="回饋金"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-form-item label="折抵方式" prop="mode">
        <el-radio-group v-model="formData.mode" @change="useModeChange">
          <el-radio label="all">全部回饋金</el-radio>
          <el-radio label="part">部分回饋金</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="折抵金額" prop="use">
        <el-input v-model="formData.use" :disabled="formData.mode === 'all'" />
      </el-form-item>
      <el-form-item label="餘額">
        {{ usedLeft }}
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <div class>
        <el-button plain @click="$emit('close')">取消</el-button>
        <el-button type="primary" @click="handleConfirm">
          確認
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { reactive, computed, ref, onMounted } from 'vue'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import formUtils from '@/utils/form'

const props = defineProps({
  balance: {
    type: [String, Number],
    default: 0,
  },
  totalPrice: {
    type: [String, Number],
    default: 0,
  },
})
const emit = defineEmits(['confirm'])
const formRef = ref(null)
const formData = reactive({
  mode: 'all',
  use: null,
})
const formRules = computed(() => {
  let max = props.balance
  if (props.balance > props.totalPrice) max = props.totalPrice
  return {
    mode: [noEmptyRules()],
    use: [noEmptyRules(), isDigitRules(), rangeRules(0, max)],
  }
})

const usedLeft = computed(() => props.balance - formData.use)

const handleConfirm = async () => {
  if (!await formUtils.checkForm(formRef.value)) return
  emit('confirm', formData.use)
  console.log('pass')
}

const useModeChange = (mode) => {
  if (mode === 'all') {
    if (props.balance > props.totalPrice) formData.use = props.totalPrice
    else formData.use = props.balance
  }
}

onMounted(() => {
  if (props.balance > props.totalPrice) formData.use = props.totalPrice
  else formData.use = props.balance
})
</script>

<style scoped lang="postcss">

</style>
